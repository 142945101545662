import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Row, Col, Table, message} from 'antd';
import {Context} from '../../AppContext';
import Dispatch from '../../Models/Dispatch';
import {navigate} from 'gatsby';
import Block from '../../Components/Block';
import Field from '../../Components/Field';
import {Edit, Add, Refresh} from '@styled-icons/material';
import DispatchForm from '../../Forms/DispatchForm';
import ProjectBlock from './ProjectBlock';
import useProject from '../../hooks/use-project';
import ProjectItem from '../../Models/ProjectItem';
import {fillZero, previousPage} from '../../Utils';
import useProcess from '../../hooks/use-processes';
import processes from '../../processes';
import {Button} from '../../Widgets';
const queryString = require('query-string');

const labels = Dispatch.labels;

const dbg = {
  id: '', // 'b3951612-984b-48b7-9a5f-65b4e30ac973',
};

export default function DispatchDetailPage(props) {
  const {id = dbg.id, p} = queryString.parse(props.location.search);
  //id = dispatch.id , p = project.id
  const edit = !!id;
  const app = useContext(Context);
  const [record, setRecord] = useState(
    edit
      ? null
      : new Dispatch({
          project: p,
        }),
  );
  const {project, items, customer, loading} = useProject({id: record?.project});

  const {renderProcessBlock} = useProcess({dispatch: record, items});

  const getRecord = useCallback(async () => {
    if (id) {
      try {
        let resp = await app.actions.getDispatch({query: {id}});
        setRecord(resp);
      } catch (ex) {
        console.warn(ex);
      }
    }
  }, [app.actions, id]);

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      await getRecord();
      app.actions.setLoading(false);
    })();
  }, [getRecord]);

  return (
    <Wrapper>
      <div className="action-bar">
        <div
          onClick={() => previousPage()}
          style={{color: '#aaa', cursor: 'pointer'}}>
          {'↼ '}上一頁
        </div>

        <div style={{flex: 1}} />

        <Button
          disabled={!edit}
          onClick={() => {
            app.actions.setLoading(true);
            navigate(`/pdf?type=dispatch&id=${record.id}`);
          }}>
          匯出工單
        </Button>
      </div>
      <Row gutter={[30, 30]}>
        <Col span={12}>
          <Block title="工單資訊">
            {record && <DispatchForm record={record} onUpdate={getRecord} />}
          </Block>
        </Col>
        <Col span={12}>
          <Row gutter={[30, 30]}>
            <Col span={24}>
              <Block title="專案資訊">
                {record && (
                  <ProjectBlock
                    record={record}
                    project={project}
                    customer={customer}
                  />
                )}
              </Block>
            </Col>
            <Col span={24}>
              <Block title="印製項目" contentStyle={{padding: 0}}>
                <TableWrapper>
                  <Table
                    loading={loading}
                    rowKey="id"
                    rowClassName={(record) =>
                      record.is_deleted ? 'deleted' : ''
                    }
                    columns={[
                      {
                        title: ProjectItem.labels['id'],
                        render: (record) => (
                          <div
                            style={{
                              textDecoration: record.is_deleted
                                ? 'line-through'
                                : 'unset',
                            }}>
                            {fillZero(record.id + 1)}
                          </div>
                        ),
                      },
                      {
                        title: ProjectItem.labels['name'],
                        dataIndex: 'name',
                        key: 'name',
                      },
                      {
                        title: ProjectItem.labels['complete_size'],
                        dataIndex: 'complete_size',
                        key: 'complete_size',
                      },
                      {
                        title: ProjectItem.labels['quantity'],
                        dataIndex: 'quantity',
                        key: 'quantity',
                      },
                      {
                        title: ProjectItem.labels['unit'],
                        dataIndex: 'unit',
                        key: 'unit',
                      },
                    ]}
                    dataSource={items}
                    pagination={false}
                  />
                </TableWrapper>
              </Block>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              backgroundColor: '#3D3D3C',
              padding: '10px 0',
            }}>
            <div
              style={{
                fontSize: 20,
                fontWeight: 500,
              }}>
              製程項目
            </div>
            {!edit && <div>完成新增程序後，才能編輯製程</div>}
          </div>
        </Col>

        {edit &&
          Object.keys(processes).map((p) => (
            <Col span={24} key={p}>
              {renderProcessBlock({name: p})}
            </Col>
          ))}
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 72px 40px;
  background-color: white;

  & > .action-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > button {
      margin-left: 10px;
    }
  }
`;

const TableWrapper = styled.div`
  & .ant-table-row.deleted {
    color: #aaa;
  }
`;
