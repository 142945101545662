import React, {useContext, useEffect, useState} from 'react';
import {Context} from '../AppContext';
import useForm from '../hooks/use-form';
import styled from 'styled-components';
import {Button} from '../Widgets';
import Dispatch from '../Models/Dispatch';
import formatValidator from '../Utils/formatValidator';
import {ErrDispatch, errorHandler} from '../errors';
import {message} from 'antd';
import {DISPATCH_NOTES} from '../dictionary';
import {date, fillZero} from '../Utils';
import {navigate} from 'gatsby';
import {Row, Col} from 'antd';

const {isNotEmpty} = formatValidator;

const LABELS = Dispatch.labels;

export default function DispatchForm({record: _record, onUpdate}) {
  const {
    renderInput,
    renderText,
    renderCheckboxGroup,
    renderDateTime,
    renderFetchSelect,
    renderSelect,
    renderCustom,
    record,
  } = useForm({record: _record});

  const app = useContext(Context);
  const {user} = app.state;
  const isEdit = !!record.id;

  const [users, setUsers] = useState([]);

  const valid = () => {
    if (!record.staff) {
      throw new ErrDispatch('業務為必填欄位');
    }
    if (!record.staff_name) {
      throw new ErrDispatch('找不到業務名稱無法產生單號');
    }
  };

  const compositeDisplayId = (values) => {
    const display_id = `W${date.getIdDate(
      values.created,
    )}${values.staff_name.slice(0, 1)}-${fillZero(values.serial_number, 4)}`;
    return display_id;
  };

  const create = async () => {
    const action = '新增';
    let success = true;
    let resp;
    app.actions.setLoading(true);
    try {
      valid(record);
      message.loading({content: '初始化工單，建立流水號', key: 'create'});

      const params = {
        query: {},
        data: record,
      };
      // params.data.staff = user.id;
      // params.data.staff_name = user.username;
      // params.data.staff_fullname = user.data.fullname;

      // step 1. add instance & generate serial_number
      resp = await app.actions.addDispatch(params);
      message.success({content: action + '成功一筆', key: 'create'});
    } catch (ex) {
      success = false;
      errorHandler(ex, action + '錯誤');
    }

    try {
      if (success && resp) {
        // step 2. composite the display_id
        message.loading({
          content: '已建立一筆工單，正在組成工單單號',
          key: 'create',
        });
        const display_id = compositeDisplayId(resp);
        await app.actions.editDispatch({
          query: {id: resp.id},
          data: {...resp, display_id},
        });
      }
    } catch (ex) {
      errorHandler(ex, action + '錯誤');
    }
    if (success && resp) {
      message.loading({content: '即將完成', key: 'create'});
      navigate(`/dispatch?id=${resp.id}`, {replace: true});
    }
    message.destroy('create');
    app.actions.setLoading(false);
  };

  const edit = async () => {
    const action = '編輯';
    app.actions.setLoading(true);
    try {
      valid(record);
      if (!record.display_id) {
        record.display_id = compositeDisplayId(record);
      }

      const params = {
        query: {id: record.id},
        data: record,
      };
      await app.actions.editDispatch(params);
      onUpdate();

      message.success(action + '成功');
    } catch (ex) {
      errorHandler(ex, action + '錯誤');
    }
    app.actions.setLoading(false);
  };

  const newVersion = async () => {
    const goOn = window.confirm(
      '確定要複製新版本，工單資訊及所有製程項目將會拷貝一份？',
    );
    if (!goOn) {
      return;
    }
    app.actions.setLoading(true);
    let success = true;
    let resp;

    try {
      valid(record);
      message.loading({
        content: '正在建置新版本，複製製程項目，請稍候...',
        key: 'create',
      });

      resp = await app.actions.addDispatchVersion({
        serial_number: record.serial_number,
        data: record,
        // display_id 沿用原本的
      });

      message.success({content: '新版本建置成功', key: 'create'});
    } catch (ex) {
      success = false;
      errorHandler(ex, '新版本建置錯誤');
    }

    if (success && resp) {
      message.loading({content: '即將完成', key: 'create'});
      navigate(`/dispatch?id=${resp.id}`);
    }
    message.destroy('create');
    app.actions.setLoading(false);
  };

  useEffect(() => {
    (async () => {
      try {
        let resp = await app.actions.getUsers();
        setUsers(resp);
      } catch (err) {}
    })();
  }, [app.actions]);

  return (
    <Wrapper>
      <div className="content">
        {/* {renderText(LABELS['id'], 'id', {hint: isEdit ? '' : '尚未新增'})} */}
        {renderText(LABELS['display_id'], 'display_id', {
          hint: (
            <span style={{color: '#bbb', fontSize: 12}}>
              注意: 流水號由工單總數計算，請勿隨意由後端刪除
            </span>
          ),
        })}

        {renderText(LABELS['version'], 'version')}
        {renderCustom(LABELS['created'], 'created', {
          render: ({value}) => <p>{date.format(value)}</p>,
        })}
        {renderDateTime(LABELS['final_date'], 'final_date')}

        {renderSelect(LABELS['staff_fullname'], 'staff', {
          options: users.map((u) => ({
            label: u.data?.fullname || u.username,
            value: u.id,
          })),
          onDepend: (id) => {
            let user = users.find((u) => u.id === id);
            return {
              staff_name: user?.username,
              staff_fullname: user?.data?.fullname,
            };
          },
        })}
        {renderSelect(LABELS['worker_name'], 'worker', {
          options: users.map((u) => ({
            label: u.data?.fullname || u.username,
            value: u.id,
          })),
        })}

        {renderCheckboxGroup(LABELS['note'], 'note', {
          options: Object.keys(DISPATCH_NOTES).map((x) => ({
            label: x,
            value: x,
          })),
        })}
      </div>
      <div className="footer">
        {isEdit && (
          <Button type="default" onClick={newVersion} style={{marginRight: 10}}>
            新版本
          </Button>
        )}
        <Button onClick={isEdit ? edit : create}>
          {isEdit ? '儲存' : '新增'}
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > .content {
    flex: 1;
  }

  & > .footer {
    border-top: solid 1px #ddd;
    padding-top: 20px;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
