import React from 'react';
import useForm from '../../hooks/use-form';
import styled from 'styled-components';
import Dispatch from '../../Models/Dispatch';

const LABELS = Dispatch.labels;

export default function ProjectBlock({record: _record, project, customer}) {
  const {renderText, renderCustom, record} = useForm({record: _record});

  return (
    <Wrapper>
      <div className="content">
        {/* {renderText(LABELS['project'], 'project')} */}
        {renderCustom('專案名稱', '專案名稱', {
          render: () => <p>{project?.name}</p>,
        })}
        {/* {renderCustom('客戶編號', '客戶編號', {
          render: () => <p>{customer?.id}</p>,
        })} */}
        {renderCustom('客戶名稱', '客戶名稱', {
          render: () => <p>{customer?.name}</p>,
        })}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > .content {
    flex: 1;
  }
`;
